import React from 'react'
import { useState } from 'react';
import leftImg from "./../images/cc-left.png";
import rightImg from "./../images/cc-right.png";
import { useLocation } from 'react-router-dom';

const AplicationSucesful = (props) => {
    // const history = useLocation();
    const [lang, setLang] = useState("al");

    // const goBackHandler = () => {
    //     window.history.back();
    // };

    const translateKey = (data, lang) => {
        if (lang === "al") {
            // let trans =  labels !== undefined &&  labels.length > 0  && labels?.filter((label) => {return label?.label === data});
            let trans = props?.labels?.filter((label) => {
                return label?.label === data;
            });
            // console.log('translate', trans[0]?.content_al);
            return trans[0]?.content_al;
        } else {
            // let trans =  labels !== undefined &&  labels.length > 0  &&  labels?.filter((label) => {return label?.label === data});
            let trans = props?.labels?.filter((label) => {
                return label?.label === data;
            });
            return trans[0]?.content_en;
        }
    };

    const handleLangChange = (e) => {
        e.preventDefault();
        if (lang === "al") {
            window.localStorage.removeItem("lang");
            setTimeout(() => {
                window.localStorage.setItem("lang", "en");
                setLang("en");
            }, 200);
        } else {
            window.localStorage.removeItem("lang");
            setTimeout(() => {
                window.localStorage.setItem("lang", "al");
                setLang("al");
            }, 200);
        }
    };
    return (

        <>
            <div className=" container langWrapper my-4">
                {/* <button className="form-control " onClick={goBackHandler}>
                    Go Back
                </button> */}

                <button className="form-control " onClick={handleLangChange}>
                    {lang === "en" ? "English" : "Shqip"}
                </button>

            </div>
            <div class="container success-container  mx-auto">
                <img src={leftImg} alt="" className="leftImg" />
                {lang == "al" ?
                    <div class="alert alert-success">
                        <h3 class="alert-heading text-center mb-3 text-uppercase">Loja ka mbaruar!</h3>
                        {/* <hr /> */}
                        <h5 className='text-center contact_us_info'>Faleminderit të gjithëve për pjesëmarrjen në lojën MONSTER.</h5>
                        <h5 className='text-center contact_us_info'>Ju urojmë sukses në aktivitetet tona të ardhshme!</h5>
                    </div>
                    // <div class="alert alert-success">
                    //     <h3 class="alert-heading text-center mb-3">JU FALEMINDERIT PËR PJESMARRJEN NË MONSTER NCP! NË RAST SE JENI FITUES, DO TË NJOFTOHENI PËRMES E-MAIL.</h3>
                    //     <hr />
                    //     <p className='text-center contact_us_info'>Nëse keni ndonjë pyetje, ju lutemi telefononi numrin e qendrës sonë të kontaktit më poshtë <br /> <a className='nav_link' href="tel:+38349745570">+383 (0) 49-745-570</a></p>
                    // </div>
                    :
                    <div class="alert alert-success">
                    <h3 class="alert-heading text-center mb-3 text-uppercase">The Game Has Ended!</h3>
                    <h5 className='text-center contact_us_info'>Thank you all for participating in the MONSTER game.</h5>
                    <h5 className='text-center contact_us_info'>We wish you success in our future activities!</h5>
                </div>
                
                    // <div class="alert alert-success">
                    //     <h3 class="alert-heading text-center mb-3">THANK YOU FOR PARTICIPATING IN THE MONSTER   NCP! IF YOU WIN, YOU WILL BE NOTIFIED BY EMAIL.</h3>
                    //     <hr />
                    //     <p className='text-center contact_us_info'>If you have any questions, please contact our call center number below <br /> <a className='nav_link' href="tel:+38349745570">+383 (0) 49-745-570</a></p>
                    // </div>
                }
                <img src={rightImg} alt="" className="rightImg" />

            </div>
        </>
    )
}

export default AplicationSucesful